import Script from 'next/script';
import React, {ReactElement} from 'react';

import {useEnvironment} from './EnvironmentProvider';

const TawkToLoader = (): ReactElement | null => {
  const environment = useEnvironment();

  if (!environment) return null;

  return (
    <Script id="tawkToLoader" type="text/javascript">
      {`var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
          (function(){
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/${environment.tawkTo.propertyId}/${environment.tawkTo.widgetId}';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
          })();
        `}
    </Script>
  );
};

export default TawkToLoader;
